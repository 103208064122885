import { type VNode } from 'vue';
import { storeToRefs } from 'pinia';
import { Teleport } from 'vue';
import { useCustomerCurrency } from '@/composables/useCustomerCurrency';
import ActionBar from '@/components/ActionBar';
import Button from '@/components/Button';
import SelectionInfo from '@/components/SelectionInfo';
import DeliveryWeekActivateButton from './DeliveryWeekActivateButton';

export default defineComponent({
  name: 'SelectProductsBar',
  setup() {
    const {
      isFetchedDeliveryWeeks,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPauseEditable,
      isSelectedDeliveryWeekPaused,
      maxRecipes,
      minRecipes,
      selectedDeliveryWeekNumberString,
    } = useDeliveryWeeks();
    const route = useRoute();
    const { t } = useI18n();
    const selectProductsStore = useSelectProductsStore();

    const { initializeSelectedProducts, saveProductChanges } =
      selectProductsStore;

    const {
      additionalProductIds,
      hasDiscount,
      hasProductSelectionChanged,
      isSaving,
      isRecipe,
      recipeIds,
      totalPreDiscounts,
      validSelection,
      totalPrice,
    } = storeToRefs(selectProductsStore);

    const { formatPrice } = useCustomerCurrency();

    const redirectedFromRecipesInfoPageString = computed<string>(() => {
      if (route?.query.redirectedFrom === 'recipes') {
        return t('select_products_bar.redirected_from_recipes_page');
      }

      return '';
    });

    const showSelectProductsBar = computed<boolean>(() => {
      const isCorrectRoute =
        String(route.name) === 'additional-products' ||
        String(route.name) === 'recipes';
      return isCorrectRoute;
    });

    const handleCancel = (): void => {
      initializeSelectedProducts();
    };

    const handleSave = (): void => {
      saveProductChanges();
    };

    return {
      handleCancel,
      handleSave,
      hasDiscount,
      hasProductSelectionChanged,
      isFetchedDeliveryWeeks,
      isSaving,
      isSelectedDeliveryWeekEditable,
      isSelectedDeliveryWeekPauseEditable,
      isSelectedDeliveryWeekPaused,
      selectedDeliveryWeekNumberString,
      redirectedFromRecipesInfoPageString,
      recipeIds,
      minRecipes,
      maxRecipes,
      additionalProductIds,
      showSelectProductsBar,
      totalPreDiscounts,
      totalPrice,
      validSelection,
      isRecipe,
      formatPrice,
    };
  },
  render(): VNode | null {
    if (!this.showSelectProductsBar) return null;
    return (
      <>
        <ActionBar
          class={{
            'has-changes': this.hasProductSelectionChanged,
            'invalid-selection':
              !this.validSelection && this.hasProductSelectionChanged,
            paused: this.isSelectedDeliveryWeekPaused,
          }}
          type="select-products"
          wrapperClasses={{
            '!invisible': !this.isFetchedDeliveryWeeks,
          }}
        >
          <div class="selection-info">
            <p class="flex-1">
              <span class="week-number">
                {this.$t('select_products_bar.week', {
                  weekNumber: this.selectedDeliveryWeekNumberString,
                })}
              </span>
              <SelectionInfo
                class="max-lg:hidden"
                isSelectedDeliveryWeekPauseEditable={
                  this.isSelectedDeliveryWeekPauseEditable
                }
                recipeCount={this.recipeIds.length}
                maxRecipes={this.maxRecipes}
                minRecipes={this.minRecipes}
                additionalProductsCount={this.additionalProductIds.length}
                isRecipe={this.isRecipe}
                isSelectedDeliveryWeekEditable={
                  this.isSelectedDeliveryWeekEditable
                }
                isSelectedDeliveryWeekPaused={this.isSelectedDeliveryWeekPaused}
              />

              <p class="info">{this.redirectedFromRecipesInfoPageString}</p>
            </p>
            {this.hasDiscount && (
              <div class="discount">
                <span class="discount-price price">
                  <s>{this.formatPrice(this.totalPreDiscounts)}</s>
                </span>
              </div>
            )}
            {
              <div class="box-price">
                <span class="price">
                  {this.formatPrice(this.totalPrice.netPrice)}
                </span>
              </div>
            }
            {this.isSelectedDeliveryWeekPaused &&
              this.isSelectedDeliveryWeekPauseEditable && (
                <DeliveryWeekActivateButton />
              )}
          </div>
          {this.hasProductSelectionChanged && (
            <div class="save-changes-control flex max-lg:hidden">
              <Button
                class="button-primary"
                disabled={!this.validSelection}
                onClick={this.handleSave}
                data-testid="select-products-save"
                spinner={this.isSaving}
              >
                {this.$t('global.save')}
              </Button>
              <Button
                class="button-stroke-white"
                onClick={this.handleCancel}
                data-testid="select-products-discard"
              >
                {this.$t('global.cancel')}
              </Button>
            </div>
          )}
        </ActionBar>

        {this.hasProductSelectionChanged && (
          <Teleport to={'body'}>
            <div class="mobile-save-changes-control lg:hidden">
              <SelectionInfo
                isSelectedDeliveryWeekPauseEditable={
                  this.isSelectedDeliveryWeekPauseEditable
                }
                recipeCount={this.recipeIds.length}
                maxRecipes={this.maxRecipes}
                minRecipes={this.minRecipes}
                additionalProductsCount={this.additionalProductIds.length}
                isRecipe={this.isRecipe}
                isSelectedDeliveryWeekEditable={
                  this.isSelectedDeliveryWeekEditable
                }
                isSelectedDeliveryWeekPaused={this.isSelectedDeliveryWeekPaused}
              />

              <p>{this.$t('select_products_bar.save_changes_info')}</p>

              <div class="buttons">
                <Button class="button-stroke" onClick={this.handleCancel}>
                  {this.$t('global.cancel')}
                </Button>
                <Button
                  class="button-primary"
                  disabled={!this.validSelection}
                  onClick={this.handleSave}
                  spinner={this.isSaving}
                >
                  {this.$t('global.save')}
                </Button>
              </div>
            </div>
          </Teleport>
        )}
      </>
    );
  },
});
