import type { VNode } from 'vue';
import { NuxtLink } from '#components';
import ActionBar from '@/components/ActionBar';

export default defineComponent({
  name: 'TemporaryPaymentProblemBar',
  setup() {
    const route = useRoute();
    const currentUser = useCurrentUser();

    const { getMyCustomerState } = useCustomerApi();
    const { data } = getMyCustomerState(currentUser.isLoggedIn);

    const showBar = computed<boolean>(() => {
      if (
        [
          'pay-delivery',
          'onboarding',
          'onboarding-add-payment-method',
          'onboarding-add-password',
        ].includes(String(route.name))
      )
        return false;
      if (!data.value) return false;

      return data.value.state === 'temporary-problem:insufficient-funds';
    });

    return {
      showBar,
    };
  },
  render(): VNode | null {
    if (!this.showBar) return null;
    return (
      <ActionBar type="temporary-payment">
        <div class="text-content">
          <h2 class="title">{this.$t('temporary_payment_problem.heading')}</h2>
          <p class="description">
            {this.$t('temporary_payment_problem.description')}
          </p>
          <p class="description">
            {this.$t('temporary_payment_problem.description2')}
          </p>
        </div>
        <NuxtLink
          class={['button', 'button-primary']}
          to={{ name: 'pay-delivery' }}
        >
          {this.$t('temporary_payment_problem.button_text')}
        </NuxtLink>
      </ActionBar>
    );
  },
});
