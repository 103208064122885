import type { VNode } from 'vue';
import Button from '@/components/Button';

export default defineComponent({
  name: 'DeliveryWeekActivateButton',
  setup() {
    const {
      isSelectedDeliveryWeekPauseEditable,
      selectedDeliveryWeek,
      selectedDeliveryWeekNumberString,
    } = useDeliveryWeeks();
    const { subscriptionId } = useCustomerSubscriptions();
    const { setWeekPause } = useDeliveriesApi();
    const noticeStore = useNoticeStore();
    const { t } = useI18n();

    const handleDeliveryWeekActivate = async (event: Event): Promise<void> => {
      event.preventDefault();
      if (
        !subscriptionId.value ||
        !selectedDeliveryWeek.value ||
        !isSelectedDeliveryWeekPauseEditable.value
      ) {
        return;
      }

      try {
        await setWeekPause(
          false,
          subscriptionId.value,
          new Date(selectedDeliveryWeek.value.paymentDate)
        );

        noticeStore.addNotice({
          text: t('week_banner.week_x_activated', {
            weekNumber: selectedDeliveryWeekNumberString.value,
          }),
          type: 'success',
        });
      } catch (error) {
        noticeStore.addNotice({
          text: t('week_banner.week_x_update_failed', {
            weekNumber: selectedDeliveryWeekNumberString.value,
          }),
          type: 'caution',
        });
      }
    };

    return {
      handleDeliveryWeekActivate,
      selectedDeliveryWeekNumberString,
    };
  },
  render(): VNode {
    return (
      <Button class="button-primary" onClick={this.handleDeliveryWeekActivate}>
        {this.$t('week_banner.activate_week_x', {
          weekNumber: this.selectedDeliveryWeekNumberString,
        })}
      </Button>
    );
  },
});
